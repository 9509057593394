import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Split Jerk 4×6\\@70%1rm`}</p>
    <p>{`Band Face Pulls 4×10`}</p>
    <p>{`then,`}</p>
    <p>{`1000m Ski Erg`}</p>
    <p>{`100 Double Unders`}</p>
    <p>{`75 Situps`}</p>
    <p>{`50 S2OH (75/55) (Rx+ 95/65)`}</p>
    <p>{`25 Pullups (Rx+ CTB Pullups)`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      